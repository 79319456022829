@import "../../../../../public/assets/scss/base/variables";

.about-page-wrap {
  p {
    margin-bottom: 30px;
  }
}

.building-style {
  fill: black !important;
  font-weight: bold !important;
  font-size: calc(-1vmin + 111.2px) !important;
}

.fill-black {
  fill: black !important;
}

.fill-white {
  fill: #DCDCDC !important;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar {
  width: 11px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #555;
}

.line {
  stroke: black;
  stroke-opacity: 0;
  stroke-width: 2px;
  cursor: pointer;
}

.polyline {
  fill: #b8c6d0;
  fill-opacity: 0;
}

.transition1 {
  transition: 0.5s;
}

.floor-text {
  fill: #fefff6;
  stroke: none;
  fill-opacity: 0;
  cursor: default;
  pointer-events: none;
  font-weight: bold;
  font-family: sans-serif;
  /* responsive font using y = mx + b */
  font-size: calc(-1vmin + 51.2px); /* (320,48)(1920,32) */
}

.text-black {
  color: $black4;
  font-weight: 500;
  letter-spacing: 0.35px;
  margin-bottom: 30px;
}

.content-right {
  padding: 50px 0px 0px 100px;
  @media (max-width: 992px) {
    padding: 30px 15px 0 15px;
  }
}

.content-left {
  padding: 50px 100px 0px 0px;
  @media (max-width: 992px) {
    padding: 0 15px;
  }
}

.about-parallax {
  padding: 110px 0px;

  p {
    font-size: 18px;
    color: $black1;
    margin-bottom: 30px;
    line-height: 1.75;
  }
}

.box-counter {
  @media (max-width: 575px) {
    margin-bottom: 30px;
  }

  .counter-nummer {
    font-size: 36px;
    font-family: $headings-font-family;
    text-transform: uppercase;
    @media (max-width: 767px) {
      font-size: 24px;
    }
  }

  .counter-text {
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.33px;
  }
}

.box-ourteam {
  border-style: solid;
  border-width: 0px 1px 0px 0px;
  border-color: #ebebeb;
  padding: 40px 40px 40px 40px;
  @media (max-width: 992px) {
    padding: 15px;
  }

  &.no-border {
    border-width: 0;
  }

  @media (max-width: 992px) {
    border-width: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 30px;
  }

  .avarta-team {
    margin-bottom: 40px;
    @media (max-width: 992px) {
      margin-bottom: 20px;
    }
  }

  .name {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .job {
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 35px;
    display: block;
    @media (max-width: 992px) {
      margin-bottom: 20px;
    }
  }

  &.flex-column-reverse {
    .content-team {
      margin-bottom: 20px;
    }
  }
}

.ourteam {
  .no-gutters {
    margin-left: -40px;
    margin-right: -40px;
    @media (max-width: 992px) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  [class*="col-"]:last-child {
    .box-ourteam {
      border-width: 0;
    }
  }
}


//apartment list
.apartment-floor-text {
  fill: black;
  fill-opacity: 0;
  cursor: default;
  pointer-events: none;
  font-weight: bold;
  font-family: Marcellus SC,serif;
  font-size: calc(-1vmin + 30.2px);
  white-space:pre;
  dominant-baseline:central;
  text-anchor:middle;
}
.apartment-price-text {
  fill: #e3493b;
  fill-opacity: 0;
  cursor: default;
  pointer-events: none;
  font-weight: bold;
  font-family: Marcellus SC,serif;
  /* responsive font using y = mx + b */
  font-size: calc(-1vmin + 30.2px); /* (320,48)(1920,32) */
}

.apartment-popup {
  fill: #fefff6 !important;
  fill-opacity: 0;
  cursor: default;
  pointer-events: none;
  font-weight: bold;
  font-family: Marcellus SC, serif;
  /* responsive font using y = mx + b */
  font-size: calc(-1vmin + 30.2px); /* (320,48)(1920,32) */
}

.sold-popup {
  fill: #fefff6;
  fill-opacity: 0;
  cursor: default;
  pointer-events: none;
  font-weight: bold;
  font-family: Marcellus SC, serif;
  /* responsive font using y = mx + b */
  font-size: calc(-1vmin + 30.2px); /* (320,48)(1920,32) */
}

.borderBlack {
  stroke: black;
  stroke-width: 1;
  fill: white !important;
}


.bookedStyle  {
  fill: orange;
}
.availableStyle  {
  fill: green;
}
.soldStyle  {
  fill: red;
}

.building-branch {
  background: #cabe9f;
  color: #fff;
  display: block;
}
.arrow {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  //background: #b7bdcb;
  position: absolute;
  top: 10px;
  left: 10px;
}

.apartment-list {
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0 0 25px grey;
  position: relative;
  z-index: 2;
}
.building-branch-value {
  line-height: 60px;
  text-transform: uppercase;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: calc(-1vmin + 25.2px);
  //padding: 0 10px;
}
.details-text {
  line-height: 54px;
  text-transform: uppercase;
  text-align: center;
  color: black;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  //font-size: calc(-1vmin + 25.2px);
}
.table-key {
  font-size: 22px;
  color: black;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
 /* @media screen and (min-width: 1292px) {
    font-size: 22px !important;
  }
  @media (max-width: 1292px) and (min-width: 992px) {
    font-size: 22px !important;
  }
  @media (max-width: 992px) and (min-width: 767px) {
    font-size: 22px !important;
  }
  @media (max-width: 767px) and (min-width: 576px) {
    font-size: 22px !important;
  }
  @media (max-width: 576px) and (min-width: 420px) {
    font-size: 22px !important;
  }
  @media (max-width: 420px) {
    font-size: 16px !important;
  }*/
}
.table-value {
  font-size: 27px;
  font-family: Montserrat, sans-serif;
  float: right;
 /* @media screen and (min-width: 1292px) {
    font-size: 27px !important;
  }
  @media (max-width: 1292px) and (min-width: 992px) {
    font-size: 27px !important;
  }
  @media (max-width: 992px) and (min-width: 767px) {
    font-size: 27px !important;
  }
  @media (max-width: 767px) and (min-width: 576px) {
    font-size: 27px !important;
  }
  @media (max-width: 576px) and (min-width: 420px) {
    font-size: 27px !important;
  }
  @media (max-width: 420px) {
    font-size: 16px !important;
  }*/
}

.header-text-size-responsive {
  @media screen and (min-width: 1292px) {
    font-size: 23px !important;
    font-weight: bold;
    letter-spacing: 2px !important;
  }
  @media (max-width: 1292px) and (min-width: 992px) {
    font-size: 21px !important;
    font-weight: bold;
    letter-spacing: 2px !important;
  }
  @media (max-width: 992px) and (min-width: 767px) {
    font-size: 21px !important;
    font-weight: bold;
    letter-spacing: 2px !important;
  }
  @media (max-width: 767px) and (min-width: 576px) {
    font-size: 20px !important;
    font-weight: bold;
    letter-spacing: 2px !important;
  }
  @media (max-width: 576px) and (min-width: 420px) {
    font-size: 16px !important;
    font-weight: bold;
    letter-spacing: 0 !important;
  }
  @media (max-width: 420px) {
    font-size: 14px !important;
    font-weight: bold;
    letter-spacing: 0 !important;
  }
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.bookedFill  {
  fill: #d66f1d
};
.soldFill {
  fill: #cc0000
};
.defaultFill {
  fill: #cabe9f
};

.soldTextStyle {
  fill: #fefff6;
  fill-opacity: 0;
  cursor: default;
  pointer-events: none;
  font-weight: bold;
  font-family: Montserrat, sans-serif;
  /* responsive font using y = mx + b */
  font-size: calc(-1vmin + 26.2px); /* (320,48)(1920,32) */
}