@import "../../../../public/assets/scss/base/variables";
.image-box {
    position: relative;
    margin: 0 10px;
    .image-box-wrap,
    .image-box-bg,
    .imgbox-bg-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
    }
    .image-box-wrap {
        overflow: hidden;
        .image-box-bg {
            will-change: transform;
            background-size: cover;
            background-position: center;
            transform: scale(1);
            transition: all ease-in-out 0.5s;
        }
        .imgbox-bg-overlay {
            background-color: #000000;
            opacity: 0.3;
            transition: all ease-in-out 0.5s;
        }
    }
    .image-box-content {
        position: relative;
        min-height: 550px;
        padding: 150px 30px;
        .subtitle {
            font-size: 10px;
            letter-spacing: 2px;
            font-weight: 600;
        }
        .title {
            font-size: 32px;
            line-height: 45px;
            @media (max-width: 768px) {
                font-size: 24px;
                line-height: 30px;
            }
        }
    }
    @media (max-width: 1200px){
        margin: 0 15px;
    }
    @media (max-width: 767px) {
        margin: 0;
    }
    &:hover {
        .image-box-bg {
            transform: scale(1.2);
            transition: all ease-in-out 0.5s;
        }
        .imgbox-bg-overlay {
            opacity: 0.65;
            transition: all ease-in-out 0.5s;
        }
    }
}