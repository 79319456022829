.titleClass {
  @media screen and (min-width: 1292px) {
    font-size: 1.875rem !important;
  }
  @media (max-width: 1292px) and (min-width: 992px) {
    font-size: 1.875rem !important;
  }
  @media (max-width: 992px) and (min-width: 767px) {
    font-size: 1.875rem !important;
  }
  @media (max-width: 767px) and (min-width: 576px) {
    font-size: 1.675rem !important;
  }
  @media (max-width: 576px) and (min-width: 420px) {
    font-size: 20px !important;
  }
  @media (max-width: 420px) {
    font-size: 20px !important;
  }
}